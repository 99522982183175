import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'hea-img-dynamic-logo',
  templateUrl: './img-dynamic-logo.component.html',
  styleUrls: ['./img-dynamic-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImgDynamicLogoComponent {
  @Input() src: string;
  @Input() alt: string;
  logoUrl = environment.whiteLabeling.logoUrl;
}
