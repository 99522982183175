<button mat-icon-button
        class="flex items-center btn-position"
        (click)="close()">
  <mat-icon class="color-medium" [svgIcon]="'close'"></mat-icon>
</button>

<div class="flex flex-col justify-center items-center">

  <h1 class="color-dark pt-2 fs-xl font-medium">Contact Us</h1>
  <mat-icon class="size-xl mt-6" svgIcon="contact-us"></mat-icon>
  <h3 class="color-dark pt-2 fs-xl font-medium mt-6">Need Help?</h3>

  <p class="pt-2 color-medium fs-sm">Hank specialists are standing by</p>

  <div class="flex items-center pt-2">
    <p class="color-medium fs-sm">Call now:&nbsp;</p>
    <a [href]="'tel:866-847-1747'" class="color-primary">
      866-847-1747</a>
  </div>

  <p class="pt-2 color-medium fs-sm mb-0">Our hours of operation:</p>
  <p class="pt-2 color-medium fs-sm">Monday-Friday 9am-5pm ET</p>

  <div class="flex items-center pt-2 pb-8">
    <p class="color-medium fs-sm">Email us:&nbsp;</p>
    <a [href]="'mailto:schoolsupport@hankpayments.com'" class="color-primary">
      schoolsupport&#64;hankpayments.com</a>
  </div>

</div>
