import { Environment, Tenant } from '@environments/environment.types';

export const environment: Environment = {
  production: false,
  whiteLabeling: {
    tenantId: Tenant.clarendon,
    logoUrl: 'assets/logo/hank/hank.png'
  },
  baseURL: 'https://hank-edu-ingo-admin-dev.uptempo.tech',
  portalApiUrl: 'https://hank-edu-admin-portal-dev.azure-api.net/',
  bursarApiUrl: 'https://bursar-payment-dev-gw.uptempo.tech',
  firebaseApiUrl: 'https://identitytoolkit.googleapis.com/v1/',
  ocpSubscriptionKey: '9ed6d1a3ebab4c4c9cdcd4932e0da8a5',
  notificationGroupName: 'DEV-ENVIRONMENT',
  firebase: {
    apiKey: 'AIzaSyBPrUdJSXqvxSaEXSE1CAjBXvK7qk5hKH8',
    authDomain: 'hank-edu-dev-b021a.firebaseapp.com',
    projectId: 'hank-edu-dev-b021a',
    appId: '1:379101347457:web:5f7ed0ecc4ee134bf3d780'
  }
};
