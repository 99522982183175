import {NgModule} from '@angular/core';

import {BtnPendingDirective} from './btn-pending.directive';
import {DownloadFileDirective} from './download-file.directive';
import {LetDirective} from './let-context.directive';
import {ContenteditableDirective} from "@shared/directives/contenteditable.directive";

const modules = [
  BtnPendingDirective,
  LetDirective,
  DownloadFileDirective,
  ContenteditableDirective
];

@NgModule({
  declarations: [...modules],
  exports: [...modules]
})
export class DirectivesModule {

}
