import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ImgDynamicLogoComponent } from './img-dynamic-logo.component';

@NgModule({
  declarations: [ImgDynamicLogoComponent],
  imports: [
    MatTooltipModule
  ],
  exports: [ImgDynamicLogoComponent]
})
export class ImgDynamicLogoModule {
}
