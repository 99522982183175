import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AddToGroupRes, NegotiateRes} from '@core/services/notifications/notifications.types';
import {environment} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class NotificationsApiService {

  constructor(private http: HttpClient) {
  }

  negotiate(userId: string): Observable<NegotiateRes> {
    const headerDict = {
      'x-application-user-id': userId,
    }

    return this.http
      .get<NegotiateRes>(`${environment.baseURL}/signalr/api/negotiate`, {headers: new HttpHeaders(headerDict)});
  }

  addToGroup(userId: string, groupName: string): Observable<AddToGroupRes> {
    const body = {
      UserId: userId,
      GroupName: groupName
    };

    return this.http
      .post<AddToGroupRes>(`${environment.baseURL}/signalr/api/AddToGroup`, body);
  }
}
