import {Pipe, PipeTransform} from '@angular/core';
type PaymentDetail = {
  CardType: string;
  CardNumber: string;
  RoutingNumber?: string;
  AccountNumber?: string;
  AccountType?: string;
  CardExpiry?: string;
};
type Item = {
  id: number;
  orderId: number;
  sku: string;
  itemType: string;
  amount: number;
};

type PaymentStatus = "Paid" | "Failed"; // Example union for status, expand as needed

export interface Receipt {
  id: number;
  idempotencyKey: string;
  orderRefId: string;
  originId: string;
  terminalId: string;
  accountId: string;
  accountName: string;
  paymentRefId: string;
  paymentPlanId: string | null;
  paymentMethod: string;
  paymentDetail: PaymentDetail;
  paymentDesc: string | null;
  secureToken: string | null;
  status: PaymentStatus;
  statusCode: number;
  statusMessage: string;
  notes: string | null;
  orderFeeTotal: number;
  orderTotal: number;
  paidBy: string;
  transactionDate: string;  // Could use Date if parsed
  createdBy: string;
  lastUpdatedBy: string;
  createdOnUtc: string;  // Could use Date if parsed
  lastUpdatedOnUtc: string;  // Could use Date if parsed
  items: Item[];
}

@Pipe({
  name: 'sumItemsAmount'
})
export class SumItemsAmountPipe implements PipeTransform {

  transform(receipt: any, itemType='tuition'): number {
    if (!receipt || !Array.isArray(receipt.items)) {
      return 0;  // Return 0 if receipt or items array is invalid
    }

    return receipt.items
      .filter(item => item.itemType === itemType)
      .reduce((sum, item) => sum + (item.amount || 0), 0);
  }
}
