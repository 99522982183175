import { NgModule } from '@angular/core';
import { FormFieldErrorModule } from '@shared/components/form-field-error';

import { AgCellsModule } from './ag-cells';
import { AgGridWrapperModule } from './ag-grid-wrapper';
import { ConfirmActionDialogModule } from './confirm-action-dialog';
import { DisbursementFileStatusModule } from './disbursement-file-status';
import { DisbursementStatusModule } from './disbursement-status';
import { EntityCreateDialogModule } from './entity-create-dialog';
import { FilterPeriodModule } from './filter-period';
import { FormFieldsModule } from './form-fields';
import { InfiniteScrollSelectorModule } from './infinite-scroll-selector';
import { SpinnerModule } from './spinner';
import { StatusModule } from './status';
import { ToastMsgModule } from './toast-msg';
import { ImgDynamicLogoModule } from '@shared/components/img-dynamic-logo';
import { CardImageModule } from '@shared/components/card-image/card-image.module';

const modules = [
  AgCellsModule,
  AgGridWrapperModule,
  InfiniteScrollSelectorModule,
  ConfirmActionDialogModule,
  DisbursementStatusModule,
  DisbursementFileStatusModule,
  FormFieldsModule,
  ImgDynamicLogoModule,
  SpinnerModule,
  StatusModule,
  ToastMsgModule,
  EntityCreateDialogModule,
  FormFieldErrorModule,
  FilterPeriodModule,
  CardImageModule
];

@NgModule({
  imports: [...modules],
  exports: [...modules],
  declarations: []
})
export class ComponentsModule {

}
